ul,
ol {

  &%default-ul,
  &%default-ol {
  	list-style-type: none;
	  margin: 0;
	  padding: 0;
  }
  
}

ul {
	list-style-type: disc;
  margin-bottom: $small-spacing;
  padding-left: $base-spacing;
}

ol {
	list-style-type: decimal;
  margin-bottom: $small-spacing;
  padding-left: $base-spacing;
}

dl {
  margin-bottom: $small-spacing;

  dt {
    font-weight: bold;
    margin-top: $small-spacing;
  }

  dd {
    margin: 0;
  }
}
