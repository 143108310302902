@charset "utf-8";

// Import partials from `sass_dir` (defaults to `_sass`)
// Includes Bourbon, Neat, and Bitters (thanks, thoughtbot!)
@import
  "bourbon/bourbon",
  "base/base",
  "neat/neat",
  "layout"
;

// Custon styling for alerts inspired by https://idratherbewriting.com/documentation-theme-jekyll/mydoc_alerts.html
.alert {
  padding: 10px 15px;
  margin-bottom: 10px;
  margin-top: 10px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert-tip {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-warning {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert-important {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}